import styled from "styled-components";

// Experience stuff here
const Wrapper = styled.div`
  height: 100vh;
  position: relative;
  scroll-snap-align: start;
`;

const Content = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1300px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  position: relative;
`;

const SkillHeader = styled.h3`
  color: #03dac6;
  margin: 0;
  font-style: italic;
  font-weight: 500;
`;

const SkillSubheader = styled.h4`
  color: #dfdfdf;
  margin: 0;
  font-style: italic;
  font-weight: 300;
`;

const Skill = styled.li`
  margin-bottom: 12px;

  @media (max-width: 750px) {
    /* Some magic right here */
    &:nth-of-type(1n+3){
      display: none;
    }
  }
`;

const Skills = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  margin-top: 24px;

  @media (max-width: 1200px) {
    position: absolute;
  }
`;

const ListWrapper = styled.ul`
  & li::marker {
    color: #03dac6;
  }
`;

const Vector = styled.div`
  border-radius: 50%;
  transform: ${props => props.transform || "scale(1)"};
  inset: ${props => props.inset || "0"};
  position: absolute;
  height: 200px;
  width: 200px;
  background: #f1f1f1;
`;

const Vectors = styled.div`
  position: relative;
  flex-grow: 1;
  opacity: 0.85;
  height: 30%; // Don't ask lol

  @media (max-width: 1200px) {
    position: absolute;
    opacity: 0.15;
    transform: scale(1.5);
    flex-grow: 0;
  }
`;

const Decoration1 = styled.div`
  position: absolute;
  /* Some magic number shit right here */
  /* These borders are 280vh and 112vw to maintain the same slope at Dec1 above,
    yet also to extend past this section to the bottom of Prompt. */
  border-bottom: 200vh solid transparent;
  border-right: 80vw solid #018276;
  opacity: 0.15;
  transform: translateX(19.5vw);
  width: 0; height: 0;
`;

const Decoration2 = styled.div`
  position: absolute;
  border-top: 200vh solid transparent;
  border-left: 80vw solid #018276;
  opacity: 0.15;
  transform: translateX(-30vw);
  width: 0; height: 0;
`;

const Link = styled.a`
  cursor: pointer;
  color: #03dac6;
  text-decoration: underline;
  font-weight: 600;

  &:hover {
    color: #02ae9e;
  }
`;

const Experience = {
  Wrapper, ListWrapper, Skills, Skill, SkillHeader, SkillSubheader, Vector, Vectors, Decoration1, Decoration2, Content, Link
};

export default Experience;
