import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { Navbar } from "./styled-components";

const List = ({pathname, showMobile, closeFn = () => {}}) => (
  <Navbar.List showMobile={showMobile}>
    <Navbar.Item>
      <Navbar.Link to="/about" onClick={() => closeFn()}>
        About
        <Navbar.Underline active={pathname === "/about"} />
      </Navbar.Link>
    </Navbar.Item>
    <Navbar.Item>
      <Navbar.Link to="/passions" onClick={closeFn}>
        Interests
        <Navbar.Underline active={pathname === "/passions"} />
      </Navbar.Link>
    </Navbar.Item>
    <Navbar.Item>
      <Navbar.Link to="/portfolio" onClick={closeFn}>
        Portfolio
        <Navbar.Underline active={pathname === "/portfolio"} />
      </Navbar.Link>
    </Navbar.Item>
    <Navbar.Item>
      <Navbar.Link to="/contact" onClick={closeFn}>
        Contact
        <Navbar.Underline active={pathname === "/contact"} />
      </Navbar.Link>
    </Navbar.Item>
  </Navbar.List>
);

// <a href="https://iconscout.com/icons/menu" target="_blank">Menu Icon</a> by <a href="https://iconscout.com/contributors/rengised">Alex Martynov</a> on <a href="https://iconscout.com">IconScout</a>
export default function NavbarComponent({ navOpacity, setNavOpacity }) {
  const { pathname } = useLocation();
  const [active, setActive] = useState();

  const closeFn = () => {
    setActive(false);
  }

  return (
    <Navbar.Wrapper>
      <Navbar.Brand img={process.env.PUBLIC_URL + "/img/support.png"} to="/" />
      {/* Desktop Nav */}
      <List pathname={pathname} showMobile={false} />
      
      {/* Mobile Nav */}
      <Navbar.Drawer show={active}>
        <List pathname={pathname} showMobile={true} closeFn={closeFn} />
      </Navbar.Drawer>
      <Navbar.Burger onClick={() => setActive(curr => !curr)}>
        {active ? (
          // X
          <span>&#10006;</span>
        ) : (
          // Burger
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
            <path d="M3 6a1 1 0 0 1 1-1h16a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1zm0 6a1 1 0 0 1 1-1h16a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1zm1 5a1 1 0 1 0 0 2h16a1 1 0 1 0 0-2H4z"/>
          </svg>
        )}
      </Navbar.Burger>
      <Navbar.Background opacity={pathname === "/" ? navOpacity : 1} />
    </Navbar.Wrapper>
  );
}
