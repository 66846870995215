import React from "react";

import { PageWrapper } from "./styled-components";
import Passions from "../components/interests";

export default function InterestsPage() {
  return <PageWrapper>
    <Passions />
  </PageWrapper>;
}
