import React from "react";

import { PageWrapper } from "./styled-components";
import Contact from "../components/contact";

export default function ContactPage() {
  return (<PageWrapper>
    <Contact />
  </PageWrapper>);
}
