import React, { useEffect, useState } from "react";

import SexyTabs from "./styled-components";

export default function SexyTabsComponent({ options, def, setActive }) {
  const [active, setActiveInner] = useState(def);

  const updateActive = (key, e) => {
    e.preventDefault();
    setActiveInner(key);
    setActive(key);
  }

  useEffect(() => {
    setActiveInner(options[0]?.name);
    setActive(options[0]?.name);
  }, [options, setActive])

  return (
    <SexyTabs.Wrapper>
      <SexyTabs.List>
        {options?.map(option => {
          return (
            <SexyTabs.Item percent={(1 / options.length || 1) * 100} key={`sexy-tab-${option.name}`} onClick={e => updateActive(option.name, e)} isActive={active === option.name}>
              <SexyTabs.Inner href="#">
                {option.name}
              </SexyTabs.Inner>
            </SexyTabs.Item>
          );
        })}
      </SexyTabs.List>
    </SexyTabs.Wrapper >
  );
}
