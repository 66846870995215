import React from "react";

import Portfolio from "./styled-components";

export default function EmptyStateComponent() {
    return (
        <Portfolio.Wrapper>
            <Portfolio.Header>Under Construction</Portfolio.Header>
            <Portfolio.Subheader>My various games will be downloadable here soon. Keep an eye out!</Portfolio.Subheader>
        </Portfolio.Wrapper>
    )
}