import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";

const Wrapper = styled.div`
  height: calc(100vh - 64px);
  width: 100%;
  position: relative;
`;

const Hero = styled.div`
  inset: 0 0 0 0;
  position: absolute;
  height: 100%;
  z-index: -2;
  transition: background-image 200ms ease;

  background-image: url(${props => props.img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 900px) {
    background-image: unset;
  }
`;

const BrandWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const Brand = styled.div`
  background-image: url(${props => props.img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  height: 500px;
  width: 500px;

  @media (max-width: 900px) {
    margin: 96px 0 24px;
    height: 200px;
    width: 300px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 1440px;
  margin: 64px auto 0;
  height: calc(100vh - 64px);

  @media (max-width: 900px) {
    height: auto;
    flex-direction: column;
  }
`;

const SwitchWrapper = styled.div`
  position: absolute;
  left: 24px;
`;

const ListWrapper = styled.ul`
  margin-top: 24px;
  & li::marker {
    color: #03dac6;
  }

  @media (max-width: 900px) {
    padding-left: 24px;
  }
`;

const Point = styled.li`
  margin-bottom: 24px;
  font-size: 20px;

  @media (max-width: 750px) {
    &:nth-of-type(1n+4){
      display: none;
    }
  }
`;

const Link = styled(RouterLink)`
  color: #03dac6;
  transition: 0.25s;

  &:hover {
    color: rgba(3, 218, 198, 0.7);
  }
`;

const ListHeader = styled.h3`
  color: #03dac6;
  margin-top: 48px;
  font-style: italic;
  font-weight: 500;

  @media (max-width: 900px) {
    margin-top: 24px;
    margin-bottom: 12px;
  }
`;

const Interests = {
  Wrapper, BrandWrapper, Hero, ContentContainer, Brand, SwitchWrapper, ListWrapper, Point, Link, ListHeader
};

export default Interests;
