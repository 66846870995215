import styled, { css } from "styled-components";

const Wrapper = styled.div`

`;

// rgba(175, 227, 100, 0.6): rgba(22, 195, 255, 0.5);
// rgba(255,255,255, 0.3): rgba(255,255,255, 0.3);
// $background: #2a2a2a;

const List = styled.ul`
  text-align: center;
  font-weight: 500;
  margin: 24px 0 0;
  padding: 0;
  position: relative;
  border-bottom: 1px solid rgba(255,255,255, 0.3);
  z-index: 1;
`;

const Item = styled.li`
  display: inline-block;
  cursor: pointer;
  background: #2a2a2a;
  padding: 0.6em 0;
  position: relative;
  width: ${props => props.percent}%;
  margin: 0 0 0 -4px;

  &::before, &::after {
    opacity: 0; transition: 0.3s ease;
  }

  /* This line is weird */
  &:active::before, &:active::after,
  &:hover::before, &:hover::after,
  &:focus::before, &:hover::after {
    opacity: 1;
  }

  // this is the shadow under the text/mask
  &:before,
    &:active:hover::before,
    &:active:focus::before {
      content: "";
      position: absolute;
      z-index: -1;
      box-shadow: 0 2px 3px rgba(255,255,255, 0.3);
      top: 50%; bottom: 0px; left: 5px; right: 5px;
      border-radius: 100px / 10px;
    }

  // this is the "diamond" mask that creates the
  // nice indent effect.
  &:after,
    &:active:hover::after,
    &:active:focus::after {
      content: "";
      background: #2a2a2a;
      position: absolute;
      width: 12px; height: 12px;
      left: 50%; bottom: -6px; margin-left: -6px;
      transform: rotate(45deg);
      box-shadow:
        inset 3px 3px 3px rgba(255,255,255, 0.3),
        inset 1px 1px 1px rgba(255,255,255, 0.3);
    }

  &:hover::before, &:focus::before {
    box-shadow: 0 2px 3px rgba(255,255,255, 0.3);
  }

  &:hover::after, &:focus::after {
    box-shadow:
      inset 3px 3px 3px rgba(255,255,255, 0.3),
      /* Idk if I have this filter, or whatever this is? */
      inset 1px 1px 1px rgba(255,255,255, 0.3);
  }

  &:focus a {
    text-decoration: underline;
  }

  &:focus { outline: none; }

  /* fuck me... NOT DRY DAMMIT */
  ${props => props.isActive && css`
    // this is the shadow under the text/mask
    &:before, &:hover::before {
        content: "";
        position: absolute;
        z-index: -1;
        opacity: 1;
        box-shadow: 0 2px 3px rgba(3, 218, 198, 1);
        top: 50%; bottom: 0px; left: 5px; right: 5px;
        border-radius: 100px / 10px;
      }

    // this is the "diamond" mask that creates the
    // nice indent effect.
    &:after, &:hover::after {
        content: "";
        background: #2a2a2a;
        position: absolute;
        width: 12px;
        height: 12px;
        left: 50%;
        bottom: -6px;
        margin-left: -6px;
        opacity: 1;
        transform: rotate(45deg);
        box-shadow:
          inset 3px 3px 3px rgba(3, 218, 198, 1),
          inset 1px 1px 1px rgba(3, 218, 198, 1);
      }

    &:focus::before {
      box-shadow: 0 2px 3px rgba(3, 218, 198, 1);
    }

    &:focus::after {
      box-shadow:
        inset 3px 3px 3px rgba(3, 218, 198, 1),
        inset 1px 1px 1px rgba(3, 218, 198, 1);
    }
  `}
`;

const Inner = styled.a`
  color: white;
  text-decoration: none;
  &:focus { outline: none; }
  /* margin-bottom: 12px;; */
  /* span { position: relative; top: -0.5em; } */
`;

const SexyTabs = {
  Wrapper, List, Item, Inner
};

export default SexyTabs;
