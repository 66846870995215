import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  scroll-snap-align: start;
  position: relative;
`;

const Container = styled.div`
  height: 60%;
  width: 70%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  transition: height 500ms, width 500ms;

  @media (max-width: 600px) {
    height: 100%;
  }

  @media (max-width: 900px) {
    width: 100%;
  }

  @media (min-width: 1300px) {
    height: 80%;
  }
`;

const ContainerBkgnd = styled.div`
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${props => props.img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0;
  transition: opacity 0.5s;
  position: absolute;
  inset: 0 0 0 0;

  ${props => props.active && css`
    opacity: 1;

    ${props => props.noBkgnd && css`
      opacity: 0.0;
    `}
  `}

  background-position-y: ${props => props.bkgndOffsetY || "0"};
`;

// Should probs move this to a shared folder...
const Button = styled(Link)`
  height: 100%;
  width: 33%;
  color: #2f2f2f;
  border: none;
  background: #03dac6;
  font-weight: 600;
  font-family: 'Raleway', sans-serif;
  text-decoration: none;
  cursor: pointer;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  transition: background-color .5s;
  position: relative;

  &:hover {
    background: #02ae9e;
  }
  @media (max-width: 900px) {
    font-size: 1rem;
    font-weight: 700;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  text-align: center;
  flex-grow: 1;
`;

const Tagline = styled.h2`
  color: #dfdfdf;
  margin: 0 0 12px;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 2rem;
  flex-grow: 3;
`;

const Prompt = {
  ContainerBkgnd, Wrapper, Button, Container, ButtonGroup, Tagline
};
export default Prompt;
