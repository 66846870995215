import React from "react";
import Hero from "./styled-components";

export default function HeroComponent() {
  return (
    <Hero.Img img={process.env.PUBLIC_URL + "/img/theboys.gif"}>
      <Hero.Header>
        Hi There
        <Hero.Subheader>Pleasure to meet you</Hero.Subheader>
      </Hero.Header>
    </Hero.Img>
  );
}
