import React, { useState, useEffect } from "react";

import { PageWrapper } from "./styled-components";
import Portfolio from "../components/portfolio";

// I'm treating this as a Container - if we run into more data heavy pages, we
// can move this guy into a container folder
const PortfolioContainer = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      // change this to projects once you fix the endpoint
      const response = await fetch("https://api.shanejann.com/blogs", {
        mode: "cors",
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      });

      switch (response.status) {
        // @todo success/fail states
        default:
          const data = await response.json();
          const mappedProjects = data?.projects.map(project => {
            const headers = project.split("\n", 6);
            const rest = project.substring(nthIndex(project, "\n", 6))
            return {
              title: headers[0].replace("title: ", ""),
              duration: +headers[1].replace("duration: ", ""),
              date_completed: +headers[2].replace("completed: ", ""),
              thumbnail: headers[3].replace("thumbnail: ", ""),
              link: headers[4].replace("link: ", ""),
              github: headers[5].replace("github: "),
              rest: rest.substring(0, 240) + "..."
             }
          });
          setProjects(mappedProjects)
          setLoading(false);
      }
    })();
  }, []);

  return (
    <Portfolio projects={projects} loading={loading} />
  )
}

export default function PortfolioPage() {
  return <PageWrapper>
    <PortfolioContainer />
  </PageWrapper>;
}

// Just a quick helper function
// @todo consider moving this to a util folder if you make one
function nthIndex(str, pat, n) {
  var L = str.length, i = -1;
  while (n-- && i++ < L) {
    i = str.indexOf(pat, i);
    if (i < 0) break;
  }
  return i;
}
