import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// import { Body } from "./styled-components";
import About from "./pages/About";
import Landing from "./pages/Landing";
import Portfolio from "./pages/Portfolio";
import Interests from "./pages/Interests";
import Contact from "./pages/Contact";
import GlobalStyles from "./styled-components";
import Navbar from "./components/misc/Navbar";

function Preload() {
  const imgs = [
    process.env.PUBLIC_URL + "/img/allishaneconner.png",
    process.env.PUBLIC_URL + "/img/animemashup.png",
    process.env.PUBLIC_URL + "/img/borderimage.svg",
    process.env.PUBLIC_URL + "/img/braum.png",
    process.env.PUBLIC_URL + "/img/brosandme.jpg",
    process.env.PUBLIC_URL + "/img/brosandme2.jpg",
    process.env.PUBLIC_URL + "/img/brosandme3.jpg",
    process.env.PUBLIC_URL + "/img/combatrdy.png",
    process.env.PUBLIC_URL + "/img/covidboys.jpg",
    process.env.PUBLIC_URL + "/img/dad.jpg",
    process.env.PUBLIC_URL + "/img/dirtbiking.jpg",
    process.env.PUBLIC_URL + "/img/family.jpg",
    process.env.PUBLIC_URL + "/img/family2.jpg",
    process.env.PUBLIC_URL + "/img/finalhero.gif",
    process.env.PUBLIC_URL + "/img/football1.jpg",
    process.env.PUBLIC_URL + "/img/footballbros.jpg",
    process.env.PUBLIC_URL + "/img/generic_site.png",
    process.env.PUBLIC_URL + "/img/goku.png",
    process.env.PUBLIC_URL + "/img/highschoolfriends.jpg",
    process.env.PUBLIC_URL + "/img/highschoolfriends2.jpg",
    process.env.PUBLIC_URL + "/img/highschoolfriends3.jpg",
    process.env.PUBLIC_URL + "/img/jandie5.png",
    process.env.PUBLIC_URL + "/img/janninc_hero.png",
    process.env.PUBLIC_URL + "/img/kidme.jpg",
    process.env.PUBLIC_URL + "/img/linkhero.png",
    process.env.PUBLIC_URL + "/img/LoL.png",
    process.env.PUBLIC_URL + "/img/LoZicon.png",
    process.env.PUBLIC_URL + "/img/madisonfriends.jpg",
    process.env.PUBLIC_URL + "/img/madisonfriends2.jpg",
    process.env.PUBLIC_URL + "/img/meandauntmo.jpg",
    process.env.PUBLIC_URL + "/img/meandbro.jpg",
    process.env.PUBLIC_URL + "/img/meandjane.jpg",
    process.env.PUBLIC_URL + "/img/meandluke.jpg",
    process.env.PUBLIC_URL + "/img/meandtheschacts.jpg",
    process.env.PUBLIC_URL + "/img/mom.jpg",
    process.env.PUBLIC_URL + "/img/momanddad.jpg",
    process.env.PUBLIC_URL + "/img/oldmanshane.jpg",
    process.env.PUBLIC_URL + "/img/OSRS.png",
    process.env.PUBLIC_URL + "/img/running.jpg",
    process.env.PUBLIC_URL + "/img/shaners.png",
    process.env.PUBLIC_URL + "/img/shoes.jpg",
    process.env.PUBLIC_URL + "/img/stpattys.jpg",
    process.env.PUBLIC_URL + "/img/support.png",
    process.env.PUBLIC_URL + "/img/surprisemadison.jpg",
    process.env.PUBLIC_URL + "/img/theboys.gif",
    process.env.PUBLIC_URL + "/img/theboys.jpg",
    process.env.PUBLIC_URL + "/img/theleap.jpg",
    process.env.PUBLIC_URL + "/img/vegascars.jpg",
    process.env.PUBLIC_URL + "/img/working.jpg",
  ];
  imgs.forEach(img => {
    const i = new Image();
    i.src = img
  })
  return null;
}

export default function App() {
  const [navOpacity, setNavOpacity] = useState(0.4);

  useEffect(() => {
    // Will only ever exist on the landing page ofc
    const landingWrapper = document.querySelector("#landing");
    landingWrapper?.addEventListener("scroll", event => {
      event?.target?.scrollTop > 300 ? setNavOpacity(1) : setNavOpacity(0.4);
    });
  }, []);
  return (
    <>
      <GlobalStyles />
      <Router>
        <Navbar navOpacity={navOpacity} />
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/passions" element={<Interests />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/" element={<Landing />} />
          {/* @todo I think we should eventually have a Blog route, for our lame blog posts :D*/}
        </Routes>
      </Router>
      <Preload />
    </>
  );
}
