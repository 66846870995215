import styled, { css } from "styled-components";

export const PageWrapper = styled.div`
  height: 100vh;
  overflow-y: visible;
  overflow-x: hidden;
  ${props => props.snap && css`
    scroll-snap-type: y mandatory;
  `}
`;
