import React from "react";

import Card from "./styled-components";

export default function CardComponent({ children, ...rest }) {
  // Logic here, if any...
  return (
    <Card {...rest}>
      {children}
    </Card>
  );
}
