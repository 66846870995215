import React from "react";

import Bio from "./styled-components";

const data = [
  {
    img: "family.jpg",
    title: "FAMILY"
  },
  {
    header: "The Jann Boys",
    paragraph: <>
      There is no motif of my childhood more prevalent than being apart of the famed "Jann Boys."
      <br />
      <br />
      From Conner, I learned to be carefree. To give to my closest friends endless loyalty. To push myself to the utmost limit for the ones that I love.
      <br />
      <br />
      From Ryan, I learned to question even the slightest preconceptions about the world, authority, and dogma. To find the meaning of life in the things that I care about.
      <br />
      <br />
      I will always love my brothers.
    </>,
    imgs: ["brosandme.jpg", "brosandme2.jpg", "brosandme3.jpg"],
    quote: "Being his real brother I could feel I live in his shadows, but I never have and I do not now. I live in his glow.",
    cite: "Michael Morpurgo"
  },
  {
    header: "Those Who I Am",
    paragraph: <>
      I've always held a deep respect for my parents, and, as a result, I have all their best traits - almost to a fault.
      <br />
      <br />
      It's a beautiful thing to grow up noticing what your parents endure for you.
      <br />
      <br />
      Yet, even more beautiful might be discovering the deeper, more complex aspects of ones' parents. Their hardships. Their beliefs. Growing up and <i>finally</i> seeing them as equals and friends.
      <br />
      <br />
      I couldn't be happier with the pair of parents God gave me.
    </>,
    imgs: ["momanddad.jpg", "dad.jpg", "mom.jpg"],
    quote: "The thing about parenting rules is there aren’t any. That’s what makes it so difficult.",
    cite: "Ewan McGregor"
  },
  {
    img: "highschoolfriends.jpg",
    title: "FRIENDS"
  },
  {
    header: "The Brotherhood",
    paragraph: <>
      While it may be excessively corny to say so, I wouldn't be here today without the amazing friends I made along the way.
      <br />
      <br />
      If my family showed me how to live, my friends gave me something to live for. They give me nostalgic nights of laughter and excitement
      <br />
      <br />
      The kind of nights where you can still smell the smokey odor of ashen logs. The days with a cool breeze. Grass between your toes. Blood, sweat, and tears all being shed together.
    </>,
    imgs: ["football1.jpg", "highschoolfriends3.jpg", "madisonfriends.jpg"],
    quote: "In the cookie of life, friends are the chocolate chips.",
    cite: "Unknown"
  },

  {
    header: "Thicker Than Blood",
    paragraph: <>
      Over the years, I've met so many amazing people. People who have taught me what it means to be a better man. So many friends that have become family with so many memories made.
      <br />
      <br />
      I can't thank you all enough for what you've given me.
      <br />
      <br />
      At the end of the day, the people I've met along the way will always be the most important thing to me. No promise of fame or riches can change that. I am who I am because of all of you.
    </>,
    imgs: ["meandtheschacts.jpg", "family2.jpg", "family3.jpg"],
    quote: "There are friends, there is family, and then there are friends that become family.",
    cite: "Unknown"
  },
]

function Event({ event }) {
  if (!event.header) {
    return <Bio.ComboHero key={event.header} img={process.env.PUBLIC_URL + "/img/" + event.img}>
      <Bio.ComboHeroTitle>{event.title}</Bio.ComboHeroTitle>
    </Bio.ComboHero>;
  };
  return (
    <Bio.ComboWrapper key={event.header}>
      <Bio.ComboText>
        <Bio.ComboTitle>{event.header}</Bio.ComboTitle>
        <Bio.ComboParagraph>{event.paragraph}</Bio.ComboParagraph>
        <Bio.Spacer />
        {event.quote && (
          <Bio.Quotes>
            {event.quote}
            <br />
            <cite> - {event.cite} </cite>
          </Bio.Quotes>
        )}
      </Bio.ComboText>
      <Bio.Outer>
        <Bio.Inner>
          <Bio.ComboImg img={process.env.PUBLIC_URL + "/img/" + event.imgs[1]} />
          <Bio.ComboImg img={process.env.PUBLIC_URL + "/img/" + event.imgs[2]} />
        </Bio.Inner>
        <Bio.ComboImg img={process.env.PUBLIC_URL + "/img/" + event.imgs[0]} />
      </Bio.Outer>
    </Bio.ComboWrapper>
  );
}

export default function BioComponent() {
  return (
    <>
      {/* Hacker powers baby */}
      <div style={{ "scrollSnapAlign": "start" }} />
      <Bio.Wrapper>
        <Bio.IntroHeader>The Life of <i>Shane Jann</i></Bio.IntroHeader>
        <Bio.IntroImg img={process.env.PUBLIC_URL + "/img/vegascars.jpg"} />
        <Bio.IntroImg img={process.env.PUBLIC_URL + "/img/vegascars.jpg"} />
        {/* @todo we should add a disclaimer here since this will all be sentimental BS lol */}
        {data.map(event => <Event key={event.header || event.title} event={event} />)}
        <Bio.Conclusion>
          <Bio.Accent as="div">I love you all</Bio.Accent>
          <Bio.Quotes>
            It is my firm belief that the best way to fix the world—a handyman's dream, if ever there was one—is to fix yourself.
            <br />
            <cite> - Jordan Peterson</cite>
          </Bio.Quotes>
        </Bio.Conclusion>
        <Bio.CollageWrapper>
          <Bio.CollageImg img={process.env.PUBLIC_URL + "/img/dirtbiking.jpg"} />
          <Bio.CollageImg img={process.env.PUBLIC_URL + "/img/running.jpg"} />
          <Bio.CollageImg img={process.env.PUBLIC_URL + "/img/meandluke.jpg"} />
          <Bio.CollageImg img={process.env.PUBLIC_URL + "/img/meandauntmo.jpg"} />
        </Bio.CollageWrapper>
      </Bio.Wrapper>
    </>
  );
}
