import React from "react";

import Conclusion from "./styled-components";

import { ReactComponent as Facebook } from "../../../assets/facebook.svg";
import { ReactComponent as Github } from "../../../assets/github.svg";
import { ReactComponent as Instagram } from "../../../assets/instagram.svg";
import { ReactComponent as Twitch } from "../../../assets/twitch.svg";
import { ReactComponent as Twitter } from "../../../assets/twitter.svg";
import { ReactComponent as Youtube } from "../../../assets/youtube.svg";

export default function ConclusionComponent() {
  return (
    <Conclusion.Wrapper>
      <Conclusion.Statement>Not convinced yet?</Conclusion.Statement>
      <Conclusion.CTA to="/about">Read More</Conclusion.CTA>
      <Conclusion.SocialWrapper>
        <a target="_blank" href="https://www.facebook.com/shane.jann" rel="noreferrer">
          <Conclusion.Social as={Facebook} />
        </a>
        <a target="_blank" href="https://github.com/shanejann" rel="noreferrer" >
          <Conclusion.Social as={Github} />
        </a>
        <a target="_blank" href="https://www.instagram.com/jann.shane/" rel="noreferrer" >
          <Conclusion.Social as={Instagram} />
        </a>
        <a target="_blank" href="https://www.twitch.tv/thejannimal" rel="noreferrer">
          <Conclusion.Social as={Twitch} />
        </a>
        <a target="_blank" href="https://twitter.com/ShaneJann1" rel="noreferrer">
          <Conclusion.Social as={Twitter} />
        </a>
        <a target="_blank" href="https://www.youtube.com/watch?v=o-YBDTqX_ZU" rel="noreferrer" >
          <Conclusion.Social as={Youtube} />
        </a>
      </Conclusion.SocialWrapper>
      <Conclusion.Icon8Cred href="https://icons8.com" target="_blank" rel="noreferrer noopener">Credit</Conclusion.Icon8Cred>
    </Conclusion.Wrapper>
  );
}
