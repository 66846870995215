import React from "react";

import Intro from "./styled-components";

export default function Introduction() {
  return (
    <Intro.Wrapper>
      <Intro.Decoration1 />
      <Intro.Decoration2 />
      <Intro.AvatarWrapper>
        <Intro.Avatar img={process.env.PUBLIC_URL + "/img/combatrdy.png"} small />
        <Intro.Avatar img={process.env.PUBLIC_URL + "/img/shaners.png"} />
        <Intro.Avatar img={process.env.PUBLIC_URL + "/img/meandbro.jpg"} small />
      </Intro.AvatarWrapper>
      <Intro.Divider />
      <Intro.Title>Software Engineer | Game Developer | Gamer | Runner</Intro.Title>
      <Intro.Description>
        Cheers! I'm Shane. I enjoy designing websites, tinkering, and the occasional run in the freezing minnesota snow. When not working, you'll find me "relaxing" on League of Legends or the latest battle royale
      </Intro.Description>
      <Intro.Quotes>
        Inaction is a slow death.
        <br />
        <cite> - Joey Schweitzer, Better Ideas</cite>
      </Intro.Quotes>
    </Intro.Wrapper>
  )
}
