import React from "react";

import Experience from "./styled-components";

import { ReactComponent as UnrealEngine } from "../../../assets/ue.svg";
import { ReactComponent as Blender } from "../../../assets/blender.svg";
import { ReactComponent as HTMLIcon } from "../../../assets/html.svg";
import { ReactComponent as ReactIcon } from "../../../assets/react.svg";

const skills = [
  {
    name: "Full-Stack Web Development",
    yrs: 5,
    points: [
      "Adept with Angular and React frontend frameworks.",
      "Experience with leading Fintech and Cryptocurrency industry experts.",
      "Designed applications for compliance - specifically for PCI and SOC regulations.",
      "Developed, maintained, and participated in the design of multiple component libraries.",
      "Proficient with backend and deployment architectures.",
    ]
  },
  {
    name: "Video Game Development",
    yrs: 3,
    points: [
      "Familiarity with Unreal Engine 4 and 5 tooling.",
      "Completion of the various official courses.",
      "Participation in multiple Game Jams with numerous team sizes.",
      <span>Multiple playable games completed and available on my <Experience.Link href="/portfolio" rel="noreferrer">Portfolio</Experience.Link> page.</span>,
      "Experience with Blender 3D modeling.",
      <span>Follow my journey of learning Game Development from scratch over on my <Experience.Link href="/blog" rel="noreferrer">Blog</Experience.Link> page.</span>
    ]
  }
]

function Skill({ skill }) {
  return (
    <>
      <Experience.SkillHeader>{skill?.name}</Experience.SkillHeader>
      <Experience.SkillSubheader>Years of experience: {skill?.yrs}</Experience.SkillSubheader>
      <Experience.ListWrapper>
        {skill?.points?.map((pt, i) => <Experience.Skill key={i}>{pt}</Experience.Skill>)}
      </Experience.ListWrapper>
    </>
  );
}

export default function ExperienceComponent() {
  return (

    <Experience.Wrapper>
      <Experience.Decoration1 />
      <Experience.Decoration2 />
      <Experience.Content>
        <Experience.Skills>
          {skills.map(skill => <Skill skill={skill} key={skill.name} />)}
        </Experience.Skills>
        <Experience.Vectors>
          {/* How's this for a hack :) */}
          <Experience.Vector as={UnrealEngine} transform="scale(1.1)" inset="0 0 0 150px" />
          <Experience.Vector as={HTMLIcon} transform="scale(0.6)" inset="10px 0 6px 299px" />
          <Experience.Vector as={ReactIcon} transform="scale(0.6)" inset="105px 101px 101px 251px" />
          <Experience.Vector as={Blender} transform="scale(0.4)" inset="90px 0 0 335px" />
        </Experience.Vectors>
      </Experience.Content>
    </Experience.Wrapper>
  );
}
