import styled from "styled-components";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
  position: relative;
  z-index: 1;
  position: relative;
`;

const SocialWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 200px;
  margin-top: 24px;
`;

const Social = styled.a`
  cursor: pointer;
  fill: white;
  transition: all 500ms;

  &:hover {
    fill: #02ae9e;
    transform: scale(1.2);
  }
`;

const Statement = styled.h3`
  color: white;
`;

const CTA = styled(Link)`
  border-radius: 4px;
  background: #03dac6;
  color: #1f1f1f;
  cursor: pointer;
  font-weight: bold;
  padding: 12px 8px;
  text-decoration: none;
  transition: all 500ms;

  &:hover {
    background: #02ae9e;
  }
`;

const Icon8Cred = styled.a`
  color: #6f6f6f;
  position: absolute;
  bottom: 8px;
  opacity: 0.5;
  /* left: 0; */
  /* right: 0; */

  &:hover {
    color: #7f7f7f;
  }
`;

const Conclusion = {
  Wrapper, SocialWrapper, Social, Statement, CTA, Icon8Cred
};

export default Conclusion;
