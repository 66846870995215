import styled from "styled-components";

const Wrapper = styled.div`
  text-align: center;
  margin: 0 auto;
`;

const Nav = styled.nav`
  margin-top: 50px;
  font-size: 15px;
  padding: 0px;
  list-style: none;
  background: #fff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  display: inline-block;
  border-radius: 50px;
  position: relative;
`;

const Tab = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: ${props => props.isActive ? "#fff" : "#777"};
  text-transform: uppercase;
  padding: 10px 20px;
  display: inline-block;
  position: relative;
  z-index: 1;
  transition-duration: 0.6s;

  i {
    margin-right: 5px;
  }
`;

const Selector = styled.div`
  height: 100%;
  display: inline-block;
  position: absolute;
  left: ${props => props.left || "0px"};
  width: ${props => props.width || "0"};
  top: 0px;
  z-index: 1;
  border-radius: 50px;
  transition-duration: 0.6s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);

  background: #05abe0;
  background: -moz-linear-gradient(45deg, #05abe0 0%, #03dac6 100%);
  background: -webkit-linear-gradient(45deg, #05abe0 0%,#03dac6 100%);
  background: linear-gradient(45deg, #05abe0 0%,#03dac6 100%);
  /* wtf is this? */
  /* filter: progid: DXImageTransform.Microsoft.gradient(startColorstr = '#05abe0', endColorstr = '#03dac6', GradientType = 1); */
`;

const Tabs = {
  Wrapper, Nav, Tab, Selector
};

export default Tabs;
