import styled, { css, keyframes } from "styled-components";
import { Link as RouterLink } from "react-router-dom";

const Wrapper = styled.div`
  height: 64px;
  width: 100vw;
  position: fixed;
  z-index: 10;
  overflow: hidden;
  display: flex;
`;

const Background = styled.div`
  position: absolute;
  inset: 0;
  filter: ${props => props.opacity < 1 ? "blur(1px)" : "none"};
  background: #212121;
  opacity: ${props => props.opacity};
  transition: opacity 500ms;

  @media (max-width: 900px) {
    opacity: 1;
  }
`;

const List = styled.ul`
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: 60%;
  max-width: 60%;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  z-index: 2;

  @media (max-width: 900px) {
    display: ${props => props.showMobile ? "flex" : console.log(props)};
    flex-direction: column;
    margin-top: 64px;
    min-width: 100%;
    max-width: 100%;
    justify-content: flex-start;
  }
`;

const Item = styled.li`
  height: 100%;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media (max-width: 900px) {
    height: 10%;
    padding: 24px
  }
`;

const Link = styled(RouterLink)`
  font-size: 1.25rem;
  text-decoration: none;
  color: #efefef;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 24px;
  background: transparent;
  transition: background-color 500ms;

  &:hover {
    background: #212121;
  }
`;

const drawline = keyframes`
  from {
    width: 1px;
  }
  to {
    width: 80%;
  }
`;

const Underline = styled.div`
  margin-top: .2rem;
  height: 1px;
  width: 2px;
  background: #03dac6;
  animation: ${drawline} 1s ease-in-out forwards;

  transition: 1s;

  ${props => props.active && css`
    box-shadow:
      0 3px 15px 2px #ffffff,  /* inner white */
      0 1px 80px 12px #00ffff; /* outer cyan */
  `}
`;

const Brand = styled(RouterLink)`
  background-image: url(${props => props.img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  height: 50px;
  width: 60px;
  left: 48px;
  top: 4px;
  z-index: 1;
  padding: 24px;

  @media (max-width: 900px) {
    left: 12px;
    // Lazy, but oh well.
    transform: scale(0.75);
  }
`;

const Burger = styled.div`
  display: none;

  @media (max-width: 900px) {
    display: inline;
    color: #fff;
    fill: #fff;
    stroke: #fff;
      position: absolute;
    right: 12px;
    z-index: 1;
    top: calc(50% - 12px);
    // padding: 24px;
  }
`;

const Drawer = styled.div`
  background: #1a1a1a;
  display: none;
  position: fixed;
  @media (max-width: 900px) {
    display: flex;
    height: 100vh;
    transition: all 1s;
    width: 100vw;
    top: ${props => props.show ? "0" : "100vh"};
  }
`;

const Navbar = {
  Brand, Background, Wrapper, List, Underline, Item, Link, Burger, Drawer
};

export { Navbar };
