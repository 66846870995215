import styled, { css, keyframes } from "styled-components";

const Description = styled.div`
  margin-top: 12px;
  text-align: center;
`;

const Title = styled.div`
  color: #03dac6;
  font-style: italic;
  margin-top: 24px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 550px;
  margin: 0 auto;
  /* padding-top: 120px; */
  min-height: 100vh;
  position: relative;
  scroll-snap-align: start;
`;

const Avatar = styled.div`
  box-shadow:
    0 0 30px 7px #fff,  /* inner white */
    0 0 70px 12px #0ff; /* outer cyan */
  border-radius: 50%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${props => props.img});
  height: 300px;
  width: 300px;
  margin-top: 120px;
  filter: brightness(120%);

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  transition: opacity 800ms, width 800ms;

  ${props => props.small && css`
    transform: scale(0.7);

    @media (max-width: 900px) {
      opacity: 0;
      width: 0;
    }
  `}
`;

const AvatarWrapper = styled.div`
  display: flex;
  max-width: 1500px;
  justify-content: space-between;
`;

const Divider = styled.hr`
  width: 120px;
  border-color: #02c4b2;
  margin: 48px 0 24px;
`;

const Quotes = styled.div`
  position: relative;
  text-align: center;
  padding: 1rem 1.2rem;
  width: 80%;
  margin: 2rem auto;

  &::before, &::after {
    font-family: FontAwesome;
    position: absolute;
    color: #03dac6;
    font-size: 34px;
  }

  &::before {
    content: "\f10d";
    top: -12px;
    margin-right: -20px;
    right: 100%;
  }

  &::after {
    content: "\f10e";
    margin-left: -20px;
    left: 100%;
    top: auto;
    bottom: -20px;
  }

  cite {
    color: #03dac6;
  }
`;

const Decoration1 = styled.div`
  position: absolute;
  border-bottom: 100vh solid transparent;
  border-left: 40vw solid #018276;
  opacity: 0.15;
  transform: translateX(-30vw);
  width: 0; height: 0;
`;

const Decoration2 = styled.div`
  position: absolute;
  border-top: 300vh solid transparent;
  border-right: 120vw solid #018276;
  opacity: 0.15;
  transform: translateX(30vw);
  width: 0; height: 0;
`;

// Damn I'm good
const Intro = {
  Avatar, AvatarWrapper, Divider, Wrapper, Description, Title, Quotes, Decoration1, Decoration2
};

export default Intro;
