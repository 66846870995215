import React, { useEffect, useState } from "react";

import Tabs from "./styled-components";

export default function TabsComponent({ options, def, setActive }) {
  // This was originally written with pure CSS and Jquery, so I'm going to do
  // some react magic to make it work

  useEffect(() => {
    // Ugh, how did it come to this - bugprone
    const defEl = document.querySelector(`#tabs-${def}`)
    if (!defEl) return;
    const rects = defEl.getClientRects()[0];
    setLeft(defEl.offsetLeft + "px");
    setWidth(rects.width + "px");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [active, setActiveInner] = useState(def);
  const [width, setWidth] = useState();
  const [left, setLeft] = useState();

  const updateActive = (key, e) => {
    e.preventDefault();
    setActiveInner(key);
    setActive(key);
    const rects = e.target.getClientRects()[0];
    setLeft(e.target.offsetLeft + "px");
    setWidth(rects.width + "px");
  }

  return (
    <Tabs.Wrapper>
      <Tabs.Nav>
        <Tabs.Selector left={left} width={width} />
        {options.map(option => {
          return (
            <Tabs.Tab id={`tabs-${option.key || option.name}`} key={option.name} isActive={active === option.name} onClick={e => updateActive(option.name, e)}>
              {option.icon && option.icon}
              {option.name}
            </Tabs.Tab>
          );
        })}
      </Tabs.Nav>
    </Tabs.Wrapper>
  );
}


// Elastic tabs alg
/*

1. Get Nav element
2. Get Selector element
3. Find length of Nav
4. Find active item        -> I can hold onto this instead
5. Find width of active item
6. Get left and width of active item
7. Setup onClick
  1. Remove active on ALL tabs (I think)
  2. Add active to clicked tab
  3. get inner width of clicked tab
  4. get position of clicked tab
  5. set CSS of Selector (left and width)
*/
