import styled from "styled-components";

const Card = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  background: #2a2a2a;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 12px 24px;
  margin: 0 24px 24px;
  border-radius: 8px;
  transition: 0.3s;
  /* flex: 1; */
  height: auto;

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
`;

export default Card;
