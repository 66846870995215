import React, { useState } from "react";

import { ReactComponent as Facebook } from "../../assets/facebook.svg";
import { ReactComponent as Github } from "../../assets/github.svg";
import { ReactComponent as Instagram } from "../../assets/instagram.svg";
import { ReactComponent as Twitch } from "../../assets/twitch.svg";
import { ReactComponent as Twitter } from "../../assets/twitter.svg";
import { ReactComponent as Youtube } from "../../assets/youtube.svg";

import Contact from "./styled-components";
import Card from "../misc/Card";
import Input from "../misc/Input";

export default function ContactComponent() {
  const [email, setEmail] = useState("");
  const [firstN, setFirstN] = useState("");
  const [lastN, setLastN] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);

  const submit = e => {
    e.preventDefault();
    if (loading) return

    (async () => {
      setLoading(true);
      // This will not work in localhost!!!!!!!!!
      // (which is okay, just assume it works lol)
      const response = await fetch("https://api.shanejann.com/subscribe", {
        mode: "cors",
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify({
          "email": email,
          "first_name": firstN,
          "last_name": lastN
        })
      });
      setLoading(false);
      switch (response.status) {
        case 200:
        case 201:
          setSuccess(true);
          break;
        default:
          setFailure(true);
          console.error("Unknown error");
      }
    })();
  }

  return (
    <Contact.Wrapper>
      <Contact.Col center>
        {success && (
          <>
            <Contact.Success>✓</Contact.Success>
            <h2 style={{ margin: "10px 0 0" }}>Awesome!</h2>
            <p>You're all subscribed! Stay tuned for updates!</p>
          </>
        )}
        {failure && (
          <>
            <Contact.Fail>
              &#x2715;</Contact.Fail>
            <h2 style={{ margin: "10px 0 0" }}>Sorry!</h2>
            <p>Something went wrong. Try again later.</p>
          </>
        )}
        {!failure && !success && (
          <Card>
            <Contact.Header>Hello There</Contact.Header>
            <Contact.Subheader>Sign up to stay tuned!</Contact.Subheader>
            <Contact.Divider />

            <form onSubmit={submit}>
              <input type="hidden" name="u" value="f1b6cb41d6073f060b327d764" />
              <input type="hidden" name="id" value="48592e0e30" />
              <Contact.Container>
                <Input label="First Name" type="text" name="MERGE1" id="MERGE1" size="25" value={firstN} onChange={e => setFirstN(e?.target?.value)} placeholder="John" required autocomplete="off" autoFocus />
                <Input label="Last Name" type="text" name="MERGE2" id="MERGE2" size="25" value={lastN} onChange={e => setLastN(e?.target?.value)} placeholder="Doe" optional autocomplete="off" />
              </Contact.Container>
              <Input label="Email" type="email" autocapitalize="off" autocorrect="off" name="MERGE0" id="MERGE0" size="25" value={email} onChange={e => setEmail(e?.target?.value)} placeholder="johndoe@gmail.com" required />
              {/* <Input label="Note" textarea required value={note} onChange={e => setNote(e?.target?.value)} /> */}
              <Contact.SubmitButton type="submit" value="Subscribe" clear disabled={loading} />
            </form>

          </Card>
        )}
      </Contact.Col>
      <Contact.Col center>
        <Contact.Statement>Follow me on social media</Contact.Statement>
        <Contact.SocialWrapper>
          <a target="_blank" href="https://www.facebook.com/shane.jann" rel="noreferrer">
            <Contact.Social as={Facebook} />
          </a>
          <a target="_blank" href="https://github.com/shanejann" rel="noreferrer" >
            <Contact.Social as={Github} />
          </a>
          <a target="_blank" href="https://www.instagram.com/jann.shane/" rel="noreferrer" >
            <Contact.Social as={Instagram} />
          </a>
          <a target="_blank" href="https://www.twitch.tv/thejannimal" rel="noreferrer">
            <Contact.Social as={Twitch} />
          </a>
          <a target="_blank" href="https://twitter.com/ShaneJann1" rel="noreferrer">
            <Contact.Social as={Twitter} />
          </a>
          <a target="_blank" href="https://www.youtube.com/watch?v=o-YBDTqX_ZU" rel="noreferrer" >
            <Contact.Social as={Youtube} />
          </a>
        </Contact.SocialWrapper>
        <Contact.Icon8Cred href="https://icons8.com" target="_blank" rel="noreferrer noopener">Credit</Contact.Icon8Cred>
      </Contact.Col>
    </Contact.Wrapper>
  );
}
