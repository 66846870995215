import styled, { keyframes } from "styled-components";

// import { ReactComponent as Border } from "../../assets/borderimage.svg";
// Hope ya like fucky CSS :D

const Wrapper = styled.div`
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const IntroImg = styled.div`
  background-image: url(${props => props.img});
  height: calc(100vh - 64px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  inset: 0;
  z-index: 0;
  text-align: center;
`;

const scaleUp = keyframes`
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
`;

const IntroHeader = styled.h1`
  animation: ${scaleUp} 1200ms;
  color: #000;
  font-weight: 600;
  font-family: 'Playfair Display', serif;
  font-size: 64px;
  z-index: 1;
  height: calc(100vh - 64px);

  @media (max-width: 900px) {
    text-align: center;
  }
`;

const ComboWrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1440px;
  margin: 0 auto;
  height: 100vh;
  padding-top: 64px;
  scroll-snap-align: start;

  &:nth-child(even) {
    flex-direction: row-reverse;

    @media (max-width: 900px) {
      flex-direction: column;
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const ComboHero = styled.div`
  background-image: url(${props => props.img});
  height: 100vh;
  width: 100vw;
  scroll-snap-align: start;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 900px) {
    display: none;
  }
`;

const ComboHeroTitle = styled.h2`
  text-transform: uppercase;
  font-weight: 700;
  position: absolute;
  font-size: 140px;
  background: #00000042;
  color: white;
  padding: 24px;
  border-radius: 4px;
  backdrop-filter: blur(3px);
`;

const ComboText = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 24px;
  height: 80%;
  margin: auto 24px;
  border: 1px solid #03dac6;

  /* background-image: url("/img/borderimage.svg"); */
  /* background-repeat: no-repeat; */
  /* background-size: contain; */

  @media (max-width: 900px) {
    flex-grow: 0;
    margin: auto 0;
    margin-top: 1px;
  }
`;

const ComboTitle = styled.h3`
  color: #03dac6;
  font-family: 'Roboto', serif;
  margin: 24px 0 0;
`;

const ComboParagraph = styled.p`
  font-size: 18px;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;

  @media (max-width: 900px) {
    font-size: 14px;
  }
`;

const ComboImg = styled.div`
  background-image: url(${props => props.img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const Outer = styled.div`
  display: flex;
  flex: 1;
  padding: 0 24px;
  height: 80%;
  margin: auto 0;
  /* overflow: auto; */

  ${ComboImg} {
    height: 100%;
    flex: 1;

  }
  @media (max-width: 900px) {
    padding: 24px;
    > ${ComboImg} {
      display: none;
    }
  }

  &:nth-child(even) {
    flex-direction: row-reverse;

    @media (max-width: 900px) {
      flex-direction: column;
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${ComboImg} {
    width: 100%;
    flex: 1;
  }
`;

const Conclusion = styled.div`
  padding-top: 128px;
  height: calc(45vh);
  scroll-snap-align: start;
  font-size: 36px;
  font-weight: 600;
  font-family: 'Raleway', sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Accent = styled.span`
  color: #03dac6;
  /* text-decoration: underline; */
`;

const CollageWrapper = styled.div`
  height: 55vh;
  width: 100vw;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const CollageImg = styled.div`
  height: 80%;
  width: 20%;
  background-image: url(${props => props.img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 12px solid #f0f0f0;
  border-bottom: 64px solid white;

  @media (max-width: 1024px) {
    height: 50%;
    width: 50%;
    border: none;
  }
`;

// CP Pasted :grimacing:
const Quotes = styled.div`
  position: relative;
  text-align: center;
  padding: 1rem 1.2rem;
  width: 65%;
  margin: 2rem auto 3rem;
  font-size: 16px;
  font-weight: 400;

  &::before, &::after {
    font-family: FontAwesome;
    position: absolute;
    color: #03dac6;
    font-size: 20px;
  }

  &::before {
    content: "\f10d";
    top: -12px;
    right: 100%;
  }

  &::after {
    content: "\f10e";
    left: 100%;
    top: auto;
    bottom: -12px;
  }

  cite {
    color: #03dac6;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const Bio = {
  Wrapper, IntroImg, Spacer, IntroHeader, ComboWrapper, ComboHero, ComboText, ComboTitle, ComboParagraph, ComboImg, CollageWrapper, Outer, Inner, Conclusion, CollageImg, Accent, Quotes, ComboHeroTitle
};


export default Bio;
