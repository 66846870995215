import styled, { css } from "styled-components";

const Wrapper = styled.div`
  margin: 24px;
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: 900px) {
    margin: 12px;
  }
`;

const Container = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

const Label = styled.label`
  margin-right: 8px;
  font-weight: 600;
`;

const Optional = styled.span`
  font-style: italic;
  opacity: .7;
`;

// @todo - let's match material io here
const RawInput = styled.input`
  height: 40px;
  min-width: 200px;
  background: #262626;
  outline: none;
  color: #cfcfcf;
  font-size: 20px;
  box-shadow: 3px 3px 3px black;
  border: none;
  border-top: 1px solid #1a1a1a;
  border-left: 1px solid #1a1a1a;
  border-right: 1px solid #4a4a4a;
  border-bottom: 1px solid #4a4a4a;
  padding: 2px 8px;

  transition: 250ms;

  ${props => props.textarea && css`
    height: auto;
    rows: 6;
    resize: none;
  `}

  &:focus {
    box-shadow: 0px 0px 5px #06dac3
  }
`;

const Input = {
  Wrapper, Label, RawInput, Container, Optional
};
export default Input;
