import React from "react";

import Portfolio from "./styled-components";
import EmptyState from "./empty-state";
import moment from "moment";

const Project = ({ project }) => {
  const hours = Math.floor(project.duration / 60);
  const minutes = project.duration % 60;
  return (
    <>
      <Portfolio.Project>
        <Portfolio.Left>
          <Portfolio.Thumbnail src={project?.thumbnail} alt="Course Image" />
          <Portfolio.Annotation>Course Length: {hours}h {minutes}m</Portfolio.Annotation>
          <Portfolio.Annotation>Date Completed: {moment.unix(project?.date_completed).format("DD MMM, YYYY")}</Portfolio.Annotation>
        </Portfolio.Left>
        <Portfolio.Right>
          <Portfolio.Title href={project.link} target="_blank" rel="noreferrer">{project?.title}</Portfolio.Title>
          <Portfolio.Desc>{project?.rest}</Portfolio.Desc>
        </Portfolio.Right>
        <Portfolio.BackLink as={Portfolio.GithubIcon} href={project.github} />
      </Portfolio.Project>
    </>
  )
}

export default function PortfolioComponent({ projects, loading }) {
  // todo - add loading state
  if (loading) {
    return ( 
      <Portfolio.LoadingContainer>
        <Portfolio.Spinner />
      </Portfolio.LoadingContainer>
    );
  }

  // todo - could also have a failure state...
  if (!projects?.length)
    return <EmptyState />;

  return (
    <Portfolio.Wrapper>
      {projects.map((project, i) => <Project project={project} key={project?.date_completed} />)}
    </Portfolio.Wrapper>
  )
}
