import React, { useState } from "react";

import Prompt from "./styled-components";

export default function PromptComponent() {
  const [active, setActive] = useState();

  const defaultLeave = () => setActive("");

  return (
    <Prompt.Wrapper>
      <Prompt.Container>
        <Prompt.ContainerBkgnd active={active === ""} noBkgnd />
        <Prompt.ContainerBkgnd active={active === "life"} img={process.env.PUBLIC_URL + "/img/allishaneconner.png"} bkgndOffsetY="40%" />
        <Prompt.ContainerBkgnd active={active === "work"} img={process.env.PUBLIC_URL + "/img/theleap.jpg"} bkgndOffsetY="35%" />
        <Prompt.ContainerBkgnd active={active === "contact"} img={process.env.PUBLIC_URL + "/img/covidboys.jpg"} />

        <Prompt.Tagline>Want to know more?</Prompt.Tagline>
        <Prompt.ButtonGroup>
          {/* I think mobile should cycle through the hovered states below, since without that, it's just a blank page more/less */}
          <Prompt.Button to="/about" onMouseEnter={() => setActive("life")} onMouseLeave={defaultLeave}>Biography</Prompt.Button>
          <Prompt.Button to="/passions" onMouseEnter={() => setActive("work")} onMouseLeave={defaultLeave}>Passions</Prompt.Button>
          <Prompt.Button to="/contact" onMouseEnter={() => setActive("contact")} onMouseLeave={defaultLeave}>Contact</Prompt.Button>
        </Prompt.ButtonGroup>
      </Prompt.Container>
    </Prompt.Wrapper>
  );
}
