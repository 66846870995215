import styled, { keyframes } from "styled-components";

const Img = styled.div`
  background-image: url(${props => props.img});
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  scroll-snap-align: start;
`;

const scaleUp = keyframes`
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
`;

const Header = styled.div`
  display: none;

  @media (max-width: 750px) {
    display: inline;
    animation: ${scaleUp} 1200ms;
    color: #03dac6;
    font-weight: 700;
    font-family: 'Playfair Display', serif;
    font-size: 48px;
    z-index: 1;
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
  }
`;

const Subheader = styled.div`
  position: absolute;
  color: #fcfcfc;
  top: 70px;
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  left: 0;
  right: 0;
`;

const Hero = {
  Img, Header, Subheader
};

export default Hero
