import styled, { keyframes } from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 64px;
`;

// Empty State
const Header = styled.h1`
  font-weight: 700;
  color: #06dac9;
  margin: 0;
`;

const Subheader = styled.h3`
  font-weight: 500;
  margin: 0;
`;

// Main Components
const Project = styled.div`
  display: flex;
  flex-direction: row;
  margin: 24px 0;
  height: 300px;
  width: 100%;
  position: relative;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const Section = styled.div`
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  padding: 48px 24px;
`;

const Left = styled(Section)`
  flex: 1;
  align-items: flex-end;

  @media (max-width: 900px) {
    display: none;
  }
`;

const Right = styled(Section)`
  flex: 2;
  align-items: flex-start;
`;

const Annotation = styled.div`
  display: block;
  color: #cfcfcf;
  font-weight: 400;

  @media (max-width: 900px) {
    display: none;
  }
`;

const Title = styled.a`
  color: #03dac6;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
`;

const Desc = styled.div`
  margin-top: 12px;
  color: #cfcfcf;
  font-weight: 200;
  max-width: 700px;
`;

const Divider = styled.hr`
  // display: ${props => props.last ? "hidden" : "block"};
`;

const Thumbnail = styled.img`
  height: 180px;
`;

const GithubIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1024 1024" fill="none" {...props} as={BackLink}>
    <path fillRule="evenodd" clipRule="evenodd" d="M8 0C3.58 0 0 3.58 0 8C0 11.54 2.29 14.53 5.47 15.59C5.87 15.66 6.02 15.42 6.02 15.21C6.02 15.02 6.01 14.39 6.01 13.72C4 14.09 3.48 13.23 3.32 12.78C3.23 12.55 2.84 11.84 2.5 11.65C2.22 11.5 1.82 11.13 2.49 11.12C3.12 11.11 3.57 11.7 3.72 11.94C4.44 13.15 5.59 12.81 6.05 12.6C6.12 12.08 6.33 11.73 6.56 11.53C4.78 11.33 2.92 10.64 2.92 7.58C2.92 6.71 3.23 5.99 3.74 5.43C3.66 5.23 3.38 4.41 3.82 3.31C3.82 3.31 4.49 3.1 6.02 4.13C6.66 3.95 7.34 3.86 8.02 3.86C8.7 3.86 9.38 3.95 10.02 4.13C11.55 3.09 12.22 3.31 12.22 3.31C12.66 4.41 12.38 5.23 12.3 5.43C12.81 5.99 13.12 6.7 13.12 7.58C13.12 10.65 11.25 11.33 9.47 11.53C9.76 11.78 10.01 12.26 10.01 13.01C10.01 14.08 10 14.94 10 15.21C10 15.42 10.15 15.67 10.55 15.59C13.71 14.53 16 11.53 16 8C16 3.58 12.42 0 8 0Z" transform="scale(64)" />
  </svg>
);

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%
`;

const Rotate = keyframes`
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  width: 50px;
  height: 50px;
  border: 4px solid lightgray;
  border-top-color: gray;
  animation: ${Rotate} 1.2s linear 0s infinite forwards;
  border-radius: 50%;
`;

const BackLink = styled.a`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 36px;
  height: 36px;
  cursor: pointer;

  path {
    fill: #384149;
    transition: all 0.5s;
  }

  &:hover {
    path {
      fill: #56626f;
    }
  }
`;

const Portfolio = {
  Header, Subheader, Wrapper, Left, Right, Project, Annotation, Title, Desc, Thumbnail, Divider, GithubIcon, BackLink, LoadingContainer, Spinner
};

export default Portfolio;
