import React, { useState } from "react";

import Input from "./styled-components";

export default function InputComponent({
  label,
  name,
  def,
  optional,
  textarea,
  ...rest
}) {
  const [value, setValue] = useState(def);

  return (
    <Input.Wrapper>
      <Input.Container>
        <Input.Label>{label}</Input.Label>
        {optional && <Input.Optional>(optional)</Input.Optional>}
      </Input.Container>
      <Input.RawInput as={textarea ? "textarea" : "input"} defaultValue={def} textarea={textarea} rows="10" {...rest} />
    </Input.Wrapper>
  );
}
