import styled, { css } from "styled-components";

const Wrapper = styled.div`
  margin-top: 64px;
  height: calc(100vh - 62px);
  display: flex;
  padding: 24px;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const Container = styled.div`
  display: flex;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const Col = styled.div`
  flex: 1;
  position: relative;

  ${props => props.center && css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  `}
`;

const Header = styled.h1`
  align-self: center;
  margin: 12px 0 8px;
`;
const Subheader = styled.h2`
  align-self: center;
  font-size: 20px;
  font-weight: 500;
  margin: 8px;
`;

const Divider = styled.hr`
  width: 120px;
  border-color: #02c4b2;
  margin: 24px 0;
  align-self: center;
`;

const SocialWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 200px;
  margin-top: 24px;
`;

const Social = styled.a`
  cursor: pointer;
  fill: white;
  transition: all 500ms;

  &:hover {
    fill: #02ae9e;
    transform: scale(1.2);
  }
`;

const Statement = styled.h3`
  color: white;
  margin-bottom: 0;
`;

const Icon8Cred = styled.a`
  color: #6f6f6f;
  position: absolute;
  bottom: 8px;
  opacity: 0.5;
  right: 8px;

  &:hover {
    color: #7f7f7f;
  }
`;

const SubmitButton = styled.input`
  width: auto;
  color: #2f2f2f;
  background: #03dac6;
  font-weight: 600;
  font-family: 'Raleway', sans-serif;
  text-decoration: none;
  margin: 48px auto 24px;
  padding: 12px;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  transition: background-color .5s;
  position: relative;
  max-width: 50%;
  text-align: center;

  &:hover {
    background: #02ae9e;
  }

  ${props => props.disabled && css`
    cursor: not-allowed;
    background: #02cfbb44;

    &:hover {
      background: #02cfbb44;
    }
  `}

  @media (max-width: 900px) {
    font-size: 1rem;
    font-weight: 700;
    max-width: 100%;
  }
`;

const Success = styled.div`
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;

  border-radius: 50%;
  border: 2px solid #22bb33;
  color: #22bb33;
`;

const Fail = styled.div`
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;

  border-radius: 50%;
  border: 2px solid #BB2124;
  color: #BB2124;
`;

const Contact = {
  Wrapper, Col, Container, Divider, Header, Subheader, SocialWrapper, Social, Statement, Icon8Cred, SubmitButton, Success, Fail
};
export default Contact;
