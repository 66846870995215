import React, { useEffect, useState } from "react";

import Interests from "./styled-components";
import Tabs from "../misc/Tabs";
import SexyTabs from "../misc/SexyTabs";
import Card from "../misc/Card";

// Yes, I know this should be in a separate file, quit houndin' me :D
const data = [
  {
    name: "Work",
    tabs: [
      {
        name: "Game Dev",
        header: "Every kid's dream",
        brand: "support.png",
        hero: "janninc_hero.png",
        points: [
          "While not official, this is my own budding company.",
          "It's still in the works but centered around game development, most commonly using Unreal Engine 5.",
          "Get in touch with me if you'd like to hear more."
        ]
      },
      {
        name: "SBTLME",
        header: "Subtle Anime Merch",
        brand: "goku.png",
        hero: "generic_site.png",
        points: [
          "Subtle anime shirts for when you want to represent, but you don't want to be too loud.",
          "We ship shirts in 6-8 weeks to anyone in the United States.",
          "The best part is, our operation is small enough where we happily take suggestions and custom orders.",
          // <p>Check out our <Interests.Link href="#" rel="noopener" target="_blank">Wix page</Interests.Link> for our current offers.</p>
          <p>Sign up for my <Interests.Link to="/contact">Newsletter</Interests.Link> if you'd like to be notified when we roll these shirts out.</p>
        ]
      }
    ]
  },
  {
    name: "Play",
    tabs: [
      {
        name: "OSRS",
        header: "Grinding the Scape",
        brand: "OSRS.png",
        hero: "jandie5.png",
        points: [
          "Old School Runescape - The one game that steals your soul while you play.",
          "This game teaches you discipline, persistence, and the meaning of hard work - all for the low cost of early-onset carpal tunnel.",
          "You might find me slogging away at slayer tasks or crafting runes for my fellow group ironmen.",
        ]
      },
      {
        name: "League",
        header: "Stand Behind Shane!",
        brand: "LoL.png",
        hero: "braum.png",
        points: [
          "League of Legends is my favorite game to hate.",
          "I'm a humble support main, who favors the champs that can keep my friends alive the longest.",
          "Like every other league player, I have fleeting dreams of hitting challenger level one day.",
        ]
      },
      {
        name: "LoZ",
        header: "Triforce of Courage",
        brand: "LoZicon.png",
        hero: "linkhero.png",
        points: [
          "The Legend of Zelda games will always hold a nostalgic place in my heart.",
          "It was the primary reason I got into video game development and undoubtably influenced who I am today",
          "I hope to one day make a game that is half as good as these masterpieces.",
          "Until then, let's beat the bad guy, save the princess, and rescue the kingdom."
        ]
      }
    ]
  }
];

export default function Passions() {
  const [activeTab, setActiveTab] = useState(data[0].name || "Work");
  const [activeSexyTab, setActiveSexyTab] = useState(data[0]?.tabs[0]?.name || "SBTLME");
  const [sexyOptions, setSexyOptions] = useState([]);

  useEffect(() => {
    const options = data.find(el => el.name === activeTab)?.tabs;
    if (!options?.length) {
      console.error("We hit a snag and couldn't find the right options - defaulting");
      return setSexyOptions(data[0]?.tabs || []);
    }
    return setSexyOptions(options);
  }, [activeTab]);

  // I understand this could be done better, but screw it
  // For future reference...set the active object, not active object's property...
  const getEl = () => {
    const outerOptions = data.find(el => el.name === activeTab)?.tabs;
    const header = outerOptions.find(el => el.name === activeSexyTab);
    return header;
  }

  return (
    <Interests.Wrapper>
      <Interests.SwitchWrapper>
        <Tabs options={data} def={activeTab} setActive={setActiveTab} />
      </Interests.SwitchWrapper>
      <Interests.ContentContainer>
        <Interests.BrandWrapper>
          <Interests.Brand img={`${process.env.PUBLIC_URL}/img/${getEl()?.brand}`} />
        </Interests.BrandWrapper>
        <Card>
          <SexyTabs options={sexyOptions} def={activeSexyTab} setActive={setActiveSexyTab} />
          <Interests.ListHeader>{getEl()?.header}</Interests.ListHeader>
          <Interests.ListWrapper>
            {getEl()?.points?.map((el, i) => <Interests.Point key={i}>{el}</Interests.Point>)}
          </Interests.ListWrapper>
        </Card>
      </Interests.ContentContainer>
      <Interests.Hero img={`${process.env.PUBLIC_URL}/img/${getEl()?.hero}`} />
    </Interests.Wrapper>
  )
}
