import React from "react";

import Hero from "../components/landing/Hero";
import Introduction from "../components/landing/Introduction";
import Prompt from "../components/landing/Prompt";
import Experience from "../components/landing/Experience";
import Conclusion from "../components/landing/Conclusion";
import { PageWrapper } from "./styled-components";

export default function Landing() {
  return (
    <PageWrapper snap id="landing">
      <Hero />
      <Introduction />
      <Prompt />
      <Experience />
      <Conclusion />
    </PageWrapper>
  )
}
