import React from "react";

import Bio from "../components/about/Bio";
import { PageWrapper } from "./styled-components";

export default function About() {
  return (
    <PageWrapper snap>
      <Bio />
    </PageWrapper>
  );

}
